// 默认菜单，没有从后台获取到使用的备用
const menus: any = [
  {
    children: [
      {
        disable: false,
        icon: null,
        idx: "/a1/c2",
        isLink: false,
        meta: {},
        name: "申请请假/销假记录",
        openType: 1,
        path: "holiday?",
        path0: "holiday",
      },
    ],
    icon: null,
    idx: "/a1",
    meta: {},
    name: "触点综合申请管理",
  },
];
export default menus;
