import global from "@/global/global";
import store from "@/store";
import "@/styles/ant-variables.less";
import "@/styles/globel.less";
import Antd from "ant-design-vue";
import PlusUi from "plus-ui-bar";
import "plus-ui-bar/lib/style.css";
import Vue from "vue";
import App from "./App.vue";
import "./permission";
import router from "./router";
import YearPicker from "@/components/YearPicker/index.vue";
import RegionCascader from "@/components/RegionCascader.vue";

// 图片预览插件
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

import rank1 from "@/assets/icon-rank1.png";
import rank2 from "@/assets/icon-rank2.png";
import rank3 from "@/assets/icon-rank3.png";

Vue.prototype.$rankImgList = [rank1, rank2, rank3];
// 千分位格式化
Vue.prototype.$formatMoney = (money: string | number) => {
  if (money == null) return "-";
  return new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(+money);
};

// 同比格式化 整数加“+”号
Vue.prototype.$formatYoy = (num: number) => {
  if (num == null) return "-";
  return num > 0 ? "+" + num : num;
};

Vue.use(Antd);
Vue.use(PlusUi);
Vue.use(VueViewer);
Vue.component("YearPicker", YearPicker);
Vue.component("RegionCascader", RegionCascader);
Vue.config.productionTip = false;
Vue.prototype.$global = global;
export default new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
