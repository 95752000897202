
import Vue from "vue";
import { listTree } from "@/api/main";

function getCascadeDataByLevel(data: any[], level: number, valueType: string) {
  const loopFn = (list: any[]) => {
    for (const item of list) {
      item.label = item.name;
      item.children = item.childList;
      item.isLeaf = item.level === level;
      if (valueType === "both") {
        item.value = `${item.name}@@${item.code}`;
      } else if (valueType === "code") {
        item.value = `${item.code}`;
      } else if (valueType === "name") {
        item.value = `${item.name}`;
      }
      if (item.isLeaf) {
        item.children = null;
      }
      if (item.children && item.children.length) {
        loopFn(item.children);
      }
    }
  };

  loopFn(data);
  return data;
}
export default Vue.extend({
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    level: {
      type: Number,
      default: 3,
    },
    // value 的类型，code / both
    valueType: {
      type: String,
      default: "name", // name 和 code 拼接， name@@code
    },
  },
  data() {
    return {
      options: [] as any[],
      regionValue: [] as any[],
    };
  },
  mounted() {
    this.regionValue = this.value.slice();
    this.getTreeList();
  },
  watch: {
    value(val) {
      this.regionValue = val.slice();
    },
  },
  methods: {
    getTreeList() {
      const sessionTree = sessionStorage.getItem("regionTree");
      if (sessionTree) {
        this.options = getCascadeDataByLevel(JSON.parse(sessionTree), this.level, this.valueType);
        return;
      }
      listTree().then((res: any) => {
        this.options = getCascadeDataByLevel(res.data, this.level, this.valueType);
        if (res.data) {
          sessionStorage.setItem("regionTree", JSON.stringify(res.data));
        }
      });
    },

    handleChange(val: string[]) {
      this.$emit("input", val);
    },

    filter(inputValue: string, path: any[]) {
      return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },
  },
});
