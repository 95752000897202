
import { UserModule } from "@/store/modules/user";
import Vue from "vue";

export default Vue.extend({
  name: "TopHeader",
  props: {
    flag: {
      type: Number,
      default: 4,
    },
  },
  methods: {
    async user(data: any) {
      UserModule.setUserInfo(data);
    },

    // 展示未读消息内容
    async showUnreadMsg(msgList: any[]) {
      if (msgList.length > 0 && this.$route.path === "/kanban") {
        // 遍历消息， 弹窗提示
        msgList.forEach((item, index) => {
          setTimeout(() => {
            this.$notification.open({
              message: "消息通知",
              description: `${item.title}  ${item.createdAt}`,
              style: { top: `50px` },
              onClick: () => {
                window.open(`${this.$store.state.User?.userInfo?.msg_pre}?id=${item.msgId}`);
                // this.$router.push({
                //   path: "/notification/allMessage/detail",
                //   query: { id: item.msgId },
                // });
              },
            });
          }, 300 * index);
        });
      }
    },

    async notice(msgList: any[]) {
      if (msgList.length > 0) {
        // 遍历消息， 弹窗提示
        console.log("有新消息了~");
        msgList.forEach((item, index) => {
          setTimeout(() => {
            this.$notification.open({
              message: "消息通知",
              description: `${item.title}  ${item.createdAt}`,
              style: { top: `50px` },
              onClick: () => {
                window.open(`${this.$store.state.User?.userInfo?.msg_pre}?id=${item.msgId}`);
                // this.$router.push({
                //   path: "/notification/allMessage/detail",
                //   query: { id: item.msgId },
                // });
              },
            });
          }, 300 * index);
        });
      }
    },
  },
});
