import request from "@/utils/request";

const baseUrl = "/api/base/v1";

// 省筛选
export const provinceFind = () => request({ url: `${baseUrl}/areaselect/province/find`, method: "get" });

// 市筛选
export const cityFind = (params: any) => request.get(`${baseUrl}/areaselect/city/find`, { params });

// 区筛选
export const areaFind = (params: any) => request.get(`${baseUrl}/areaselect/area/find`, { params });

// 县筛选
export const townFind = (params: any) => request.get(`${baseUrl}/areaselect/town/find`, { params });

// 品牌下拉框
export const skuBrandList = (params: any) => request.get(`/api/pxt-report/v1/fdm/skuBrand/list`, { params });

// 品类/子品类下拉框
export const skuCategoryList = (params: any) => request.get(`/api/pxt-report/v1/fdm/skuCategory/list`, { params });

// 经销商列表
export const skuDealerList = (params: any) => request.get(`/api/pxt-report/v1/fdm/dealer/list`, { params });

// 省市区树
export const listTree = (params: any = { level: 3 }) => request.get(`${baseUrl}/areaselect/listTree`, { params });
