
import { defineComponent } from "vue";
export default defineComponent({
  name: "ReportLayout",

  data() {
    return { routes: [] as any[] };
  },
  created() {
    this.init();
  },
  computed: {
    pageTitle() {
      if (this.routes.length) {
        return this.$route?.meta?.title;
      }
      return "";
    },
  },
  methods: {
    init() {
      const routes = this.$route.matched
        .filter((item) => {
          return item.name !== "rpath";
        })
        .map((item) => {
          return {
            path: item.path,
            breadcrumbName: item.meta.title,
          };
        });

      routes.unshift({
        path: "/",
        breadcrumbName: "数据报表",
      });

      this.routes = routes;
    },

    itemRender({ route, h }: any) {
      if (route.path === "/") {
        return h(
          "a",
          {
            on: {
              click: () => {
                const role = sessionStorage.getItem("role_dateSource") || "store";
                this.$router.push({
                  path: "/",
                  query: { role },
                });
              },
            },
          },
          "数据报表",
        );
      } else {
        return h("span", {}, route.breadcrumbName);
      }
    },
  },
  watch: {
    $route(to, from) {
      this.init();
    },
  },
});
