import store from "@/store";
import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface IMenuState {
  menuListMap: { [key: string]: any }; // 菜单列表
  menuItemKey: string; // 当前选中的菜单项
  menuItemChg: boolean; // 菜单项是否改变
  childRoutes: any; // 子页面路由
  menuShowFlg: number; // 菜单栏是否隐藏
}

@Module({ dynamic: true, store, name: "Menu" })
class Menu extends VuexModule implements IMenuState {
  public menuListMap = {};
  public menuItemKey = "";
  public menuItemChg = false;
  public childRoutes = {};
  public menuShowFlg = 127; // 业务触发为0/127, sidebar触发为128/255
  public menuShowMsk = 0; // 最高位标识是否显示菜单栏, 必须小于127

  @Mutation
  SET_MENU_LIST_MAP(map: { [key: string]: any }) {
    this.menuListMap = map;
  }

  @Mutation
  SET_MENU_ITEM_KEY(key: string) {
    this.menuItemKey = key;
    this.menuItemChg = !this.menuItemChg;
  }

  @Mutation
  SET_CHILD_ROUTES(params: any) {
    this.childRoutes = params;
  }

  @Mutation
  SET_MENU_SHOW_FLG(flag: number) {
    this.menuShowFlg = (flag & (this.menuShowMsk | 128)) | (this.menuShowFlg & 127 & ~this.menuShowMsk);
  }

  @Mutation
  SET_MENU_SHOW_ALL(flag?: number) {
    this.menuShowFlg = flag === undefined ? 127 : flag & 127;
    this.menuShowMsk = 0;
  }

  @Mutation // 配置掩码
  SET_MENU_SHOW_MSK(mask?: number) {
    this.menuShowMsk = mask === undefined ? 0 : mask;
  }
}
export const MenuModule = getModule(Menu);
