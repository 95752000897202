
import { defineComponent } from "vue";
export default defineComponent({
  name: "YearPicker",
  props: ["value"],

  data() {
    return {
      open: false,
    };
  },

  methods: {
    handleOpenChange(status: any) {
      this.open = status;
    },
    handlePanelChange(value: any) {
      this.$emit("input", value.format("YYYY"));
      this.open = false;
    },

    handleChange(value: any) {
      this.$emit("input", value != null ? value.format("YYYY") : null);
    },
  },
});
