import ico1 from "@/assets/ico1.png";
import ico2 from "@/assets/ico2.png";
import ico3 from "@/assets/ico3.png";
import ico4 from "@/assets/ico4.png";
import ReportLayout from "@/components/ReportLayout.vue";

// 应用路由
const routers = [
  {
    path: "/homepage",
    name: "homepage",
    component: () => import("@/views/Homepage/index.vue"),
  },

  {
    path: "/reportComputed",
    name: "reportComputed",
    component: () => import("@/views/ReportComputed/index.vue"),
  },
  // 客户详情页面
  {
    path: "/customerDetail",
    name: "customerDetail",
    component: () => import("@/views/BusinessAnalysis/detail/Customer/index.vue"),
  },
  // 商品销售详情页面
  {
    path: "/productSalesDetail",
    name: "productSalesDetail",
    component: () => import("@/views/BusinessAnalysis/detail/ProductSales/index.vue"),
  },
  // 商品活跃详情页面
  {
    path: "/productActivityDetail",
    name: "productActivityDetail",
    component: () => import("@/views/BusinessAnalysis/detail/ProductActivity/index.vue"),
  },
  // 业务员活跃详情页面
  {
    path: "/salesRepresentativeActivityDetail",
    name: "salesRepresentativeActivityDetail",
    component: () => import("@/views/BusinessAnalysis/detail/SalesRepresentativeActivity/index.vue"),
  },
  // 促销活动详情页面
  {
    path: "/activitiesSummaryDetail",
    name: "activitiesSummaryDetail",
    component: () => import("@/views/BusinessAnalysis/detail/ActivitiesSummary/index.vue"),
  },
  // 品牌看数据门店统计详情页面
  {
    path: "/storeStatistics",
    name: "StoreStatistics",
    component: () => import("@/views/BrandViewData/detail/StoreStatistics/index.vue"),
  },
  // 品牌看数据品类份额详情页面
  {
    path: "/categoryShare",
    name: "CategoryShare",
    component: () => import("@/views/BrandViewData/detail/CategoryShare/index.vue"),
  },
  {
    path: "/developing",
    name: "developing",
    redirect: "/businessAnalysis",
    component: () => import("@/views/Developing/index.vue"),
    children: [
      // 经营分析
      {
        path: "/businessAnalysis",
        name: "BusinessAnalysis",
        component: () => import("@/views/BusinessAnalysis/index.vue"),
      },
      // 经营分析-经销商与品牌谈资源
      {
        path: "/talkAboutResources",
        name: "TalkAboutResources",
        component: () => import("@/views/TalkAboutResources/index.vue"),
      },
      // 经营分析-经销商与品牌谈资源 - 品类在经销商份额
      {
        path: "/categoryDetail",
        name: "CategoryDetail",
        component: () => import("@/views/TalkAboutResources/CategoryDetail.vue"),
      },
      // 经营分析-品牌看数据
      {
        path: "/brandViewData",
        name: "BrandViewData",
        component: () => import("@/views/BrandViewData/index.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/homepage",
  },

  {
    path: "/dataSource",
    name: "dataSource",
    meta: { title: "数据源表" },
    component: ReportLayout,
    children: [
      {
        path: "terminalOrder",
        name: "terminalOrder",
        meta: { title: "订单数据", subTitle: "Order Data", icon: ico3, role: ["brand", "store"] },
        component: () => import("@/views/fdm/terminalOrder/index.vue"),
      },
      {
        path: "dealerOrder",
        name: "dealerOrder",
        meta: { title: "采购订单", subTitle: "Purchase Order", icon: ico1, role: ["brand", "store"] },
        component: () => import("@/views/fdm/dealerOrder/index.vue"),
      },
      {
        path: "pmrVisitRecord",
        name: "pmrVisitRecord",
        meta: { title: "门店拜访", subTitle: "Store visits", icon: ico2, role: ["store"] },
        component: () => import("@/views/fdm/pmrVisitRecord/index.vue"),
      },
      {
        path: "pmrVisitTarget",
        name: "pmrVisitTarget",
        meta: { title: "拜访目标", subTitle: "Visit Targets", icon: ico4, role: ["brand", "store"] },
        component: () => import("@/views/fdm/pmrVisitTarget/index.vue"),
      },
      {
        path: "terminalEffort",
        name: "terminalEffort",
        meta: { title: "门店卖力", subTitle: "Store Effort", icon: ico4, role: ["brand"] },
        component: () => import("@/views/fdm/terminalEffort/index.vue"),
      },
    ],
  },

  // 经销商与品牌谈资源
  {
    path: "/resource",
    name: "resource",
    meta: { title: "经销商与品牌谈资源" },
    component: ReportLayout,
    children: [
      {
        // 品类权重门店
        path: "statistics",
        name: "statistics",
        meta: { title: "品类权重门店" },
        component: () => import("@/views/fdm/statistics/index.vue"),
      },
      {
        // 经销商所占城市份额（汇总）
        path: "city",
        name: "city",
        meta: { title: "经销商所占城市份额（汇总）" },
        component: () => import("@/views/fdm/city/index.vue"),
      },
      {
        // 品类在经销商的份额（汇总）
        path: "cate",
        name: "cate",
        meta: { title: "品类在经销商的份额（汇总）" },
        component: () => import("@/views/fdm/cate/index.vue"),
      },
      {
        // 子品类在经销商的份额（汇总）
        path: "subcate",
        name: "subcate",
        meta: { title: "子品类在经销商的份额（汇总）" },
        component: () => import("@/views/fdm/subcate/index.vue"),
      },
      {
        // 各品牌在经销商的份额（汇总）
        path: "brand",
        name: "brand",
        meta: { title: "各品牌在经销商的份额（汇总）" },
        component: () => import("@/views/fdm/brand/index.vue"),
      },
      {
        // 品牌ND（汇总）
        path: "brandNd",
        name: "brandNd",
        meta: { title: "品牌ND（汇总）" },
        component: () => import("@/views/fdm/brandNd/index.vue"),
      },
      {
        // 单品在经销商的份额排行（汇总）
        path: "sku",
        name: "sku",
        meta: { title: "单品在经销商的份额排行（汇总）" },
        component: () => import("@/views/fdm/sku/index.vue"),
      },
      {
        // 单品ND（汇总）
        path: "skuNd",
        name: "skuNd",
        meta: { title: "单品ND（汇总）" },
        component: () => import("@/views/fdm/skuNd/index.vue"),
      },
      {
        // 人员效率（汇总）
        path: "biStaffEfficiency",
        name: "biStaffEfficiency",
        meta: { title: "人员效率（汇总）" },
        component: () => import("@/views/fdm/biStaffEfficiency/index.vue"),
      },
      {
        // 促销活动
        path: "activity",
        name: "activity",
        meta: { title: "促销活动" },
        component: () => import("@/views/fdm/activity/index.vue"),
      },
      {
        // 促销活动（汇总）
        path: "collect",
        name: "collect",
        meta: { title: "促销活动（汇总）" },
        component: () => import("@/views/fdm/collect/index.vue"),
      },
    ],
  },
  // 经销商看数据
  {
    path: "/store",
    name: "store",
    meta: { title: "经销商看数据" },
    component: ReportLayout,
    children: [
      {
        // 客户相关
        path: "terminal",
        name: "terminal",
        meta: { title: "客户相关" },
        component: () => import("@/views/fdm/terminal/index.vue"),
      },
      {
        // 客户动态变化汇总1
        path: "terminalDynamic1",
        name: "terminalDynamic1",
        meta: { title: "客户动态变化（汇总）-1" },
        component: () => import("@/views/fdm/terminalDynamic1/index.vue"),
      },
      {
        // 客户动态变化汇总2
        path: "terminalDynamic2",
        name: "terminalDynamic2",
        meta: { title: "客户动态变化（汇总）-2" },
        component: () => import("@/views/fdm/terminalDynamic2/index.vue"),
      },
      {
        // 按客户统计汇总
        path: "terminalSummary",
        name: "terminalSummary",
        meta: { title: "按客户统计（汇总）" },
        component: () => import("@/views/fdm/terminalSummary/index.vue"),
      },
      {
        // 按行政区统计（汇总）
        path: "terminalRegionSummary",
        name: "terminalRegionSummary",
        meta: { title: "按行政区统计（汇总）" },
        component: () => import("@/views/fdm/terminalRegionSummary/index.vue"),
      },
      {
        // 线上线下交易分布（汇总）
        path: "terminalOnlineOfflineSummary",
        name: "terminalOnlineOfflineSummary",
        meta: { title: "线上线下交易分布（汇总）" },
        component: () => import("@/views/fdm/terminalOnlineOfflineSummary/index.vue"),
      },
      {
        // 品牌区域销售占比（汇总）
        path: "brandDistrictSalesRatioSum",
        name: "brandDistrictSalesRatioSum",
        meta: { title: "品牌区域销售占比（汇总）" },
        component: () => import("@/views/fdm/brandDistrictSalesRatioSum/index.vue"),
      },
      {
        // 商品效能评估
        path: "skuEfficiencyEvaluate",
        name: "skuEfficiencyEvaluate",
        meta: { title: "商品效能评估" },
        component: () => import("@/views/fdm/skuEfficiencyEvaluate/index.vue"),
      },
      {
        // 商品效能评估（汇总）
        path: "skuEfficiencyEvaluateSum",
        name: "skuEfficiencyEvaluateSum",
        meta: { title: "商品效能评估（汇总）" },
        component: () => import("@/views/fdm/skuEfficiencyEvaluateSum/index.vue"),
      },
      {
        // 促销活动效果评估
        path: "evaluate",
        name: "evaluate",
        meta: { title: "促销活动效果评估" },
        component: () => import("@/views/fdm/evaluate/index.vue"),
      },
      {
        // 促销活动效果评估（汇总1）
        path: "collect1",
        name: "collect1",
        meta: { title: "促销活动效果评估（汇总1）" },
        component: () => import("@/views/fdm/collect1/index.vue"),
      },
      {
        // 促销活动效果评估（汇总2）
        path: "collect2",
        name: "collect2",
        meta: { title: "促销活动效果评估（汇总2）" },
        component: () => import("@/views/fdm/collect2/index.vue"),
      },
      {
        // 促销活动效果评估（汇总3）
        path: "collect3",
        name: "collect3",
        meta: { title: "促销活动效果评估（汇总3）" },
        component: () => import("@/views/fdm/collect3/index.vue"),
      },
      {
        // 促销活动效果评估（汇总4）
        path: "collect4",
        name: "collect4",
        meta: { title: "促销活动效果评估（汇总4）" },
        component: () => import("@/views/fdm/collect4/index.vue"),
      },
      {
        // 销售相关
        path: "salesRelated",
        name: "salesRelated",
        meta: { title: "销售相关" },
        component: () => import("@/views/fdm/salesRelated/index.vue"),
      },
      {
        // 每日销售统计（汇总）
        path: "salesStatisticsDay",
        name: "salesStatisticsDay",
        meta: { title: "每日销售统计（汇总）" },
        component: () => import("@/views/fdm/salesStatisticsDay/index.vue"),
      },
      {
        // 近三个月销售统计（汇总）-1
        path: "salesStatisticsMonth",
        name: "salesStatisticsMonth",
        meta: { title: "月销售统计" },
        component: () => import("@/views/fdm/salesStatisticsMonth/index.vue"),
      },
      {
        //近三个月销售统计（汇总）-2
        path: "salesStatisticsMonth3",
        name: "salesStatisticsMonth3",
        meta: { title: "近三个月销售统计（汇总）" },
        component: () => import("@/views/fdm/salesStatisticsMonth3/index.vue"),
      },
      {
        // 业务员人效
        path: "salespersonEfficiencyOne",
        name: "salespersonEfficiencyOne",
        meta: { title: "业务员人效" },
        component: () => import("@/views/fdm/salespersonEfficiencyOne/index.vue"),
      },
      {
        // 业务员人效（汇总）
        path: "biSalespersonEfficiencyWeek",
        name: "biSalespersonEfficiencyWeek",
        meta: { title: "业务员人效（汇总）" },
        component: () => import("@/views/fdm/biSalespersonEfficiencyWeek/index.vue"),
      },
      {
        // 新客
        path: "biNewTeminalOne",
        name: "biNewTeminalOne",
        meta: { title: "新客" },
        component: () => import("@/views/fdm/biNewTeminalOne/index.vue"),
      },
      {
        // 新客（汇总）
        path: "biNewTeminalWeek",
        name: "biNewTeminalWeek",
        meta: { title: "新客（汇总）" },
        component: () => import("@/views/fdm/biNewTeminalWeek/index.vue"),
      },
      {
        // 客户分层（汇总）
        path: "terminalStratifySummary",
        name: "terminalStratifySummary",
        meta: { title: "客户分层（汇总）" },
        component: () => import("@/views/fdm/terminalStratifySummary/index.vue"),
      },
    ],
  },
  // 品牌看数据
  {
    path: "/brand",
    name: "brand",
    meta: { title: "品牌看数据" },
    component: ReportLayout,
    children: [
      {
        // 门店变化
        path: "dealerTerminal",
        name: "dealerTerminal",
        meta: { title: "门店变化" },
        component: () => import("@/views/fdm/dealerTerminal/index.vue"),
      },
      {
        // 门店变化汇总
        path: "dealerTerminalSummary",
        name: "dealerTerminalSummary",
        meta: { title: "门店变化（汇总）" },
        component: () => import("@/views/fdm/dealerTerminalSummary/index.vue"),
      },
      {
        // 卖力分析
        path: "dealerTerminalSales",
        name: "dealerTerminalSales",
        meta: { title: "卖力分析" },
        component: () => import("@/views/fdm/dealerTerminalSales/index.vue"),
      },
      {
        // 商品活跃度
        path: "dealerTerminalSku",
        name: "dealerTerminalSku",
        meta: { title: "商品活跃度" },
        component: () => import("@/views/fdm/dealerTerminalSku/index.vue"),
      },
      {
        // 销售份额Share（汇总）
        path: "biSalesShareSummery",
        name: "biSalesShareSummery",
        meta: { title: "销售份额Share（汇总）" },
        component: () => import("@/views/fdm/biSalesShareSummery/index.vue"),
      },
      {
        // 经销商份额（品牌汇总）
        path: "biTeminalShareBrandSummery",
        name: "biTeminalShareBrandSummery",
        meta: { title: "经销商份额（品牌汇总）" },
        component: () => import("@/views/fdm/biTeminalShareBrandSummery/index.vue"),
      },
      {
        // 分销ND（汇总）
        path: "biDistributionNdSummery",
        name: "biDistributionNdSummery",
        meta: { title: "分销ND（汇总）" },
        component: () => import("@/views/fdm/biDistributionNdSummery/index.vue"),
      },
      {
        // 品牌分销ND（汇总）
        path: "biDistributionNdBrandSummery",
        name: "biDistributionNdBrandSummery",
        meta: { title: "品牌分销ND（汇总）" },
        component: () => import("@/views/fdm/biDistributionNdBrandSummery/index.vue"),
      },
      {
        // 人员效率（汇总）品牌看数据
        path: "biStaffEfficiencyBrand",
        name: "biStaffEfficiencyBrand",
        meta: { title: "人员效率（汇总）" },
        component: () => import("@/views/fdm/biStaffEfficiencyBrand/index.vue"),
      },
      {
        // 促销活动
        path: "dealerTerminalPromotionAct",
        name: "dealerTerminalPromotionAct",
        meta: { title: "促销活动" },
        component: () => import("@/views/fdm/dealerTerminalPromotionAct/index.vue"),
      },
      {
        // 促销活动（汇总）
        path: "dealerTerminalProActSummary",
        name: "dealerTerminalProActSummary",
        meta: { title: "促销活动（汇总）" },
        component: () => import("@/views/fdm/dealerTerminalProActSummary/index.vue"),
      },
    ],
  },
];
export default routers;
