
import DefMenus from "@/router/modules/menus";
import { MenuModule } from "@/store/modules/menu";
import Vue from "vue";
export default Vue.extend({
  name: "LeftMenu",
  props: ["flag"],
  data() {
    return {
      hide: false,
      defaultMenu: [...DefMenus],
      rname: document.title,
      rpath: process.env.BASE_URL === "/" ? undefined : process.env.BASE_URL,
    };
  },
  watch: {
    flag: {
      handler(val: number) {
        console.log("sidebar-flag", val);
        if (val < 128) {
          this.hide = !val; // 避免自己触发自己
        }
      },
      immediate: true,
    },
    $route() {
      // console.log("sidebar-route", this.$refs.plusMenu);
      // console.log("sidebar-route", this.$route);
      if (!this.$route.query?.m || MenuModule.menuItemKey === this.$route.query?.m) {
        return; // 路由内容已经处理，无需再次处理
      }
      const mkey = (this.$route.query.m as any) || "";

      const item = MenuModule.menuListMap[mkey];
      if (item?.idx && (item.openType === 3 || item.openType === 11 || (!item.isLink && item.openType === 1))) {
        // 内链打开页面，修改左侧菜单选中情况
        if (item.idx !== (this.$refs.plusMenu as any).selectKey) {
          (this.$refs.plusMenu as any).selectKey = item.idx;
        }
        MenuModule.SET_MENU_ITEM_KEY(mkey);
        console.log("sidebar-back", mkey);
      }
    },
  },
  methods: {
    clickHideButton() {
      MenuModule.SET_MENU_SHOW_FLG(this.hide ? 255 : 128);
      this.hide = !this.hide;
    },
    // 回调方法，获取菜单内容
    getMenuInfo(menuData: any) {
      // console.log(menuData)
      document.title = menuData.info.title;
      let mkey = "";
      let mmap = {};
      let item = {};
      // 0为根节点
      mmap["m0"] = menuData.menu;
      if (menuData.info?.skeys?.length) {
        // 最后一个selectedKey即是选中的菜单
        const skey = menuData.info.skeys[menuData.info.skeys.length - 1];
        // 定义一个栈，将menuData.menu压入栈中
        const stack = [];
        stack.push(...menuData.menu);
        // 循环栈，如果栈不为空，就取出栈顶元素
        while (stack.length) {
          const element = stack.pop();
          // const key = element.idx
          const key = element.idx.substring(element.idx.lastIndexOf("/") + 1);
          // 将栈顶元素放入listMap中
          mmap[key] = element;
          // 如果栈顶元素的idx等于skey，就将栈顶元素赋值给item
          if (element.idx === skey) {
            // mkey = element.idx;
            // break; // 跳出循环
            mkey = key;
            item = element;
          }
          // 如果栈顶元素有子元素，就将子元素压入栈中
          if (element.children && element.children.length) {
            stack.push(...element.children);
          }
        }
      }
      if (this.$route.query.m && this.$route.query.m !== mkey) {
        mkey = "" + this.$route.query.m;
      }
      MenuModule.SET_MENU_LIST_MAP(mmap);
      MenuModule.SET_MENU_ITEM_KEY(mkey); // 必定是内链菜单，直接设定选中
      console.log("sidebar-init", mkey);
      setTimeout(() => {
        const target = mmap[mkey];
        if (target) {
          const selectKey = mmap[mkey].idx || "";
          const expandkeys = selectKey
            .split("/")
            .filter((item: string) => !!item)
            .map((item: any) => mmap[item].idx);
          (this.$refs.plusMenu as any).selectChange(selectKey);
          (this.$refs.plusMenu as any).expandChange(expandkeys);
        }
      }, 0);

      // 修改当前路由，增加 query.m = mkey path: (item as any).path,
      this.$router.replace({ query: { ...this.$route.query, m: mkey } }).catch((e) => {});
    },
    // 回调方法，菜单点击回调
    clickMenuItem(event: any) {
      if (!event.data?.idx) {
        console.log("sidebar-next", "error", event); // 无效的菜单
        return;
      }
      if (!event.data?.path) {
        return; // 目录菜单，跳过
      }
      const mkey = event.data.idx.substring(event.data.idx.lastIndexOf("/") + 1);
      console.log("sidebar-next", event.data.idx, mkey);
      if (event.data.openType === 3) {
        //
        event.selectChange(event.data.idx); // 选中菜单
        this.$router.push({ name: "ipath", query: { p: event.data.path0, m: mkey } }).catch((e) => {});
        MenuModule.SET_MENU_ITEM_KEY(mkey); // 替换选中的idx相关内容
      } else if (event.data.openType === 11 || (!event.data.isLink && event.data.openType === 1)) {
        //
        event.selectChange(event.data.idx); // 选中菜单
        this.$router?.push({ path: event.data.path, query: { m: mkey } }).catch((e) => {});
        MenuModule.SET_MENU_ITEM_KEY(mkey); // 替换选中的idx相关内容
        // 屏蔽TitleBar组件内默认处理
        if (event.data.openType === 1) {
          event.data.openType = 11;
        }
      }
    },
  },
});
